import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const Footer = () => {
    const { t } = useTranslation();
    const availableLanguages = ['en', 'de', 'ru']
    console.log(i18next.language)
    return (
        <footer class="bg-primary-gradient">
            <div className='container mb-3'>
            <hr/>
            <div class="d-flex justify-content-between align-items-center pt-3">
                <ul class="list-inline">
                    <a href='/imprint' dangerouslySetInnerHTML={{__html: t('footer_imprint')}}></a>
                    <span> </span>
                    <a href='/privacy'  dangerouslySetInnerHTML={{__html: t('footer_privacy')}}></a>
                </ul>
            </div>
            <p class="mb-0">Copyright © 2024 Aleksandr Perevalov</p>
            </div>
    </footer>
    )
}

export default Footer;