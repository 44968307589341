import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'
import { useTranslation } from 'react-i18next';

const IndexCertifications = () => {
    const { t } = useTranslation();
    return (
        
        <div className="container text-center py-5">
            <div className="row text-center">
            <h1 style={{fontFamily: 'Courier New'}} dangerouslySetInnerHTML={{__html: t('certificates_title')}}></h1>
            </div>
            <div className="row justify-content-center py-5">
                <div className="col-12 col-md-6"><a href="https://www.coursera.org/account/accomplishments/verify/NAT9T8NRACJD"> <img class="m-2" style={{ width: '100%' }} src="https://s3.amazonaws.com/coursera_assets/meta_images/generated/CERTIFICATE_LANDING_PAGE/CERTIFICATE_LANDING_PAGE~NAT9T8NRACJD/CERTIFICATE_LANDING_PAGE~NAT9T8NRACJD.jpeg"></img></a></div>
                <div className="col-12 col-md-6"><a href="https://www.coursera.org/account/accomplishments/verify/BNRQREP8G5P3"> <img class="m-2" style={{ width: '100%' }} src="https://s3.amazonaws.com/coursera_assets/meta_images/generated/CERTIFICATE_LANDING_PAGE/CERTIFICATE_LANDING_PAGE~BNRQREP8G5P3/CERTIFICATE_LANDING_PAGE~BNRQREP8G5P3.jpeg"></img></a></div>
                <div className="col-12 col-md-6"><a href="https://www.coursera.org/account/accomplishments/verify/CG38H5KLGHLH"> <img class="m-2" style={{ width: '100%' }} src="https://s3.amazonaws.com/coursera_assets/meta_images/generated/CERTIFICATE_LANDING_PAGE/CERTIFICATE_LANDING_PAGE~CG38H5KLGHLH/CERTIFICATE_LANDING_PAGE~CG38H5KLGHLH.jpeg"></img></a></div>
                <div className="col-12 col-md-6"><a href="https://www.coursera.org/account/accomplishments/verify/CUVPVUHFH7VG"> <img class="m-2" style={{ width: '100%' }} src="https://s3.amazonaws.com/coursera_assets/meta_images/generated/CERTIFICATE_LANDING_PAGE/CERTIFICATE_LANDING_PAGE~CUVPVUHFH7VG/CERTIFICATE_LANDING_PAGE~CUVPVUHFH7VG.jpeg"></img></a></div>
                <div className="col-12 col-md-6"><a href="https://www.kaggle.com/certification/competitions/perevalov540/jigsaw-multilingual-toxic-comment-classification"> <img class="m-2" style={{ width: '100%' }} src="/img/kaggle.png"></img></a></div>
            </div>
        </div>
    )
}

export default IndexCertifications;