import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const Privacy = () => {
    const { t } = useTranslation();
    const availableLanguages = ['en', 'de', 'ru']
    return (
        <div className='container' style={{fontFamily: 'Courier New'}} dangerouslySetInnerHTML={{__html: t('privacy')}}></div>
    )
}

export default Privacy;